export const catalogMat = Signalman.featureFlag('catalog_mat');
export const subcatRedesign = Signalman.featureFlag('subcat_redesign_v5');
// prettier-ignore
export const newPricingDetails = Signalman.featureFlag('plp_pricing_details_v1');
export const previewUpload = Signalman.featureFlag('preview_upload_v9');
export const reviewSummarization = Signalman.featureFlag(
  'review_summarization_v1'
);
export const medalliaWidget = Signalman.featureFlag('medallia_widget_v4');
export const collapsedFilters = Signalman.featureFlag('collapsed_filters_v3');
export const generalSplitTest = Signalman.featureFlag('general_split_test_v0');
